<template>
    <div class="signup">
        <!-- Banner -->
        <base-inner-banner
            :is-custom-banner="true"
            :banner="banner"
        ></base-inner-banner>
        <!-- Banner -->

        <div class="content-wrapper">
            <div class="container">
                <h1 class="page__head--title">{{ $t('ZAINIAC SIGN UP') }}</h1>
                <div class="page-contents">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="position-relative">
                                <div class="greyBg"></div>
                                <base-form ref="form" @submit="submit">
                                    <h2>
                                        {{ $t('Become an ideator today!') }}
                                    </h2>
                                    <base-input
                                        :label="`${$t('Full name')}*`"
                                        name="fullname"
                                        :placeholder="
                                            $t('Please enter your name')
                                        "
                                        :value="userProfile.name"
                                        v-model="userProfile.name"
                                        rules="fullName|required"
                                        type="text"
                                    />
                                    <div class="form-group">
                                        <label class="control-label"
                                            >{{ $t('Opco') }}*</label
                                        >
                                        <base-select
                                            v-model="userProfile.opco"
                                            :options="countries"
                                            @input="changeOPCO()"
                                            :required="true"
                                        ></base-select>
                                    </div>
                                    <base-input
                                        :label="`${$t('Email address')}*`"
                                        name="email"
                                        :placeholder="
                                            $t(
                                                'Please enter your email address',
                                            )
                                        "
                                        :value="userProfile.email"
                                        v-model="userProfile.email"
                                        type="email"
                                        :rules="
                                            `required||email||emailDomain:${selectedOPCO}`
                                        "
                                    />
                                    <base-input
                                        :label="`${$t('Password')}*`"
                                        name="password"
                                        :placeholder="
                                            $t('Please enter your password')
                                        "
                                        :value="userProfile.password"
                                        v-model="userProfile.password"
                                        type="password"
                                        :rules="`required||password`"
                                    />
                                    <base-input
                                        :label="$t('Job title')"
                                        name="title"
                                        :placeholder="
                                            $t('Please enter your job title')
                                        "
                                        :value="userProfile.title"
                                        v-model="userProfile.title"
                                        type="text"
                                        :required="false"
                                    />
                                    <base-input
                                        :label="$t('Department')"
                                        name="department"
                                        :placeholder="
                                            $t('Please enter your department')
                                        "
                                        :value="userProfile.department"
                                        v-model="userProfile.department"
                                        type="text"
                                        :required="false"
                                    />
                                    <!-- <base-input
                                        label="Division"
                                        name="division"
                                        placeholder="Please Enter your division"
                                        :value="userProfile.division"
                                        v-model="userProfile.division"
                                        type="text"
                                        :required="false"
                                    /> -->
                                    <base-phone-input
                                        :label="$t('SignupPhone')"
                                        :required="false"
                                        v-model="userProfile.phone"
                                        :value="userProfile.phone"
                                        class="signup-mob"
                                    >
                                    </base-phone-input>
                                    <!-- <base-input
                                        label="Experience"
                                        name="experience"
                                        placeholder="Please Enter your experience"
                                        :value="userProfile.experience"
                                        v-model="userProfile.experience"
                                        type="text"
                                        :required="false"
                                    /> -->
                                    <div class="button-row">
                                        <button
                                            type="submit"
                                            class="btn btn-primary"
                                        >
                                            {{ $t('Submit') }}
                                        </button>
                                    </div>
                                </base-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/contact-us-banner-1200X400.png'
import imgLarge from '@/assets/images/banners/contact-us-banner-1600X500.png'
import imgBig from '@/assets/images/banners/contact-us-banner-1920X600.png'
import { SettingsHelper } from '@/common/crud-helpers/settings'
import { UsersHelper } from '@/common/crud-helpers/users'
import { Notify } from 'notiflix'
import { localize } from 'vee-validate'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
            },
            banner: '',
            countries: [
                {
                    value: '@zain.com',
                    text: this.$t('Zain Group'),
                    en_text: 'Zain Group',
                },
                {
                    value: '@bh.zain.com',
                    text: this.$t('Bahrain'),
                    en_text: 'Bahrain',
                },
                {
                    value: '@iq.zain.com',
                    text: this.$t('Iraq'),
                    en_text: 'Iraq',
                },
                {
                    value: '@jo.zain.com',
                    text: this.$t('Jordan'),
                    en_text: 'Jordan',
                },
                { value: '@sa.zain.com', text: this.$t('KSA'), en_text: 'KSA' },
                {
                    value: '@kw.zain.com',
                    text: this.$t('Kuwait'),
                    en_text: 'Kuwait',
                },
                {
                    value: '@omantel.om',
                    text: this.$t('Omantel'),
                    en_text: 'Omantel',
                },
                {
                    value: '@ss.zain.com',
                    text: this.$t('South Sudan'),
                    en_text: 'South Sudan',
                },
                {
                    value: '@sd.zain.com',
                    text: this.$t('Sudan'),
                    en_text: 'Sudan',
                },
                {
                    value: '@zc.zain.com',
                    text: this.$t('Zain Cash'),
                    en_text: 'Zain Cash',
                },
                {
                    value: '@zaintech.com',
                    text: this.$t('ZainTech'),
                    en_text: 'ZainTech',
                },
            ],
            userProfile: {
                name: '',
                opco: '',
                email: '',
                title: '',
                // division: '',
                // experience: '',
            },
        }
    },
    mounted() {
        localize(this.$i18n.locale, this.dictionary[this.$i18n.locale])
        if (!this.userProfile.opco) {
            this.userProfile.opco = this.countries[0].value
        }
    },
    async created() {
        document.body.classList.add('signup-page')
        if (!this.dashboardSettings || !this.dashboardSettings.login) {
            await SettingsHelper.getDashboardSettings()
        } else if (this.dashboardSettings && this.dashboardSettings.login) {
            this.setSignUpBannerAndText()
        }
    },
    watch: {
        dashboardSettings() {
            this.setSignUpBannerAndText()
        },
        '$i18n.locale': function() {
            localize(this.$i18n.locale, this.dictionary[this.$i18n.locale])
        },
    },
    computed: {
        ...mapGetters(['userAuth', 'dashboardSettings']),
        userCountry() {
            return this.userProfile.opco.length > 0
                ? this.countries.find(
                      country => country.value === this.userProfile.opco,
                  ).en_text
                : ''
        },
        selectedOPCO() {
            return this.userProfile.opco === '@zain.com'
                ? ['@kuwaitnet.com', '@zain.com']
                : this.userProfile.opco === '@sa.zain.com'
                ? ['@sa.zain.com', '@sales.sa.zain.com', '@corp.sa.zain.com']
                : this.userProfile.opco === '@zc.zain.com'
                ? ['@iq.zain.com', '@jo.zain.com']
                : this.userProfile.opco
        },
        dictionary() {
            return {
                en: {
                    messages: {
                        fullName: () =>
                            'Please enter both first and last names',
                        email: () => 'Please enter a valid email address',
                        emailDomain: () =>
                            'Email domain should be of the selected OPCO',
                        password: () =>
                            'Password should have min 8 characters, and includes uppercase and numeric chars as well',
                    },
                },
                ar: {
                    messages: {
                        fullName: () => 'الرجاء إدخال الاسم الأول والأخير',
                        email: () => 'يرجى إدخال البريد الإلكتروني الصحيح',
                        emailDomain: () =>
                            'البريد الالكتروني ليس تابعا لل (OPCO) المختار',
                        password: () =>
                            'يجب أن تحتوي كلمة المرور على 8 أحرف كحد أدنى ، وتتضمن أحرفًا كبيرة ورقمية أيضًا',
                    },
                },
            }
        },
    },
    methods: {
        setSignUpBannerAndText() {
            this.banner = this.dashboardSettings.login.background_img
        },
        async submit() {
            const isValid = await this.$refs.form.$refs.observer.validate()
            const first_name = this.userProfile.name.split(' ')[0]
            const last_name = this.userProfile.name.substring(
                first_name.length,
                this.userProfile.name.length,
            )
            const phone =
                this.userProfile.phone.length > 8 ? this.userProfile.phone : ''
            if (isValid) {
                let formData = new FormData()
                formData.append('first_name', first_name)
                formData.append('last_name', last_name)
                formData.append('email', this.userProfile.email)
                formData.append('country', this.userCountry)
                formData.append('phone', phone)
                formData.append('title', this.userProfile.title)
                // formData.append('division', this.userProfile.division)
                // formData.append('experience', this.userProfile.experience)
                formData.append('password', this.userProfile.password)

                UsersHelper.signupNewUser({ data: formData })
                    .then(() => {
                        Notify.Success(
                            'Confirm your acoount from the email we send to your email.',
                        )
                        this.$router.push({ name: 'home' })
                    })
                    .catch(err => {
                        Notify.Failure(
                            err.email && err.email.length
                                ? err.email[0]
                                : err.phone && err.phone.length
                                ? err.phone[0]
                                : 'An error occurred while creating user. Please try again',
                        )
                    })
            } else {
                Notify.Failure(
                    this.$t('Please check the below form for errors'),
                )
            }
        },
        changeOPCO() {
            // console.log('opco is:==>', val)
        },
    },
    destroyed() {
        document.body.classList.remove('signup-page')
    },
}
</script>
<style lang="scss">
.signup-page {
    .container {
        max-width: 1240px;
    }
}
</style>

<style lang="scss" scoped>
.container {
    max-width: 1240px;
}
@media (min-width: 992px) {
    .col-lg-12 {
        max-width: 50%;
    }
}
/deep/ .v-select {
    box-shadow: -2px 0px 20px rgba(0, 0, 0, 0.15);
    .v-select-toggle {
        padding: rem(14px) rem(22px) !important;
        color: #009b74 !important;
        font-weight: 400 !important;
        font-size: rem(18px) !important;
        .arrow-down {
            right: 22px;
            zoom: 0.5;
        }
        &:active,
        &:focus {
            color: var(--white) !important;
        }
    }
}
.content-wrapper {
    padding-top: 15rem;
    /deep/.page__head {
        &--title {
            margin: 0;
            color: var(--secondary);
        }
    }
}
.signup {
    /deep/.v-select {
        .v-select-toggle {
            &:focus,
            &:active {
                color: var(--white) !important;
            }
        }
    }
    .page-contents {
        padding-bottom: 7.8125rem;
        padding-top: 23rem;
        @media screen and (max-width: 1400px) {
            padding-top: 26rem;
        }
        @media screen and (max-width: 991px) {
            padding-top: 23rem;
        }
        @media screen and (max-width: 767px) {
            padding-top: 24rem;
        }
    }
    .greyBg {
        --h: 455px;
        width: 100%;
        top: 1.25rem;
        &::before {
            background: rgba(245, 225, 44, 0.1);
        }
        @media screen and (max-width: 991px) {
            width: 95%;
        }
    }
    /deep/ form {
        max-width: 490px;
        @media screen and (max-width: 991px) {
            margin: auto;
        }
        .form-control::placeholder {
            color: var(--dark);
        }
        .form-group {
            .invalid-feedback {
                font-size: 1rem;
            }
        }
    }
    h2 {
        color: var(--primary);
        font-size: 2.625rem;
        font-weight: 900;
        margin: 0 0 3.125rem 0;
        text-transform: uppercase;
        @media screen and (max-width: 1400px) {
            font-size: 2.375rem;
            margin: 0 0rem 40px 0;
        }
        @media screen and (max-width: 767px) {
            font-size: 1.875rem;
        }
    }
}
</style>
<style>
.signup-mob .v-select-toggle {
    height: 54px;
}

.signup-mob .v-select .v-select-toggle {
    align-items: center;
    position: relative;
}

.signup-mob .v-select .v-select-toggle .arrow-down {
    top: 10px;
    -moz-transform: scale(0.5);
    background-size: contain;
    background-repeat: no-repeat;
    position: initial;
    margin-top: 0 !important;
    transform: none;
}

.ar .signup-mob .v-select .v-select-toggle div {
    direction: ltr;
}

.ar .v-select-toggle[data-v-138dff1d] .arrow-down {
    right: auto !important;
}
</style>
